<template>
  <div class="container FileListView">
    <div>
      <span class="back" @click="back">
        <i class="el-icon-arrow-left"></i> 返回
      </span>
    </div>
    <div class="commonWhiteBg">
      <div class="commonTitle">{{ subject }}</div>
      <el-table
        :data="fileList"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="fileName">
          <!-- eslint-disable-next-line -->
          <template slot="header" slot-scope="scope">
            <div class="custom-header">
              总共 <span class="green"> {{ fileList.length }} </span> 个{{
                itemType == "2" ? "取证" : "存证"
              }}文件
            </div>
          </template>
          <template slot-scope="scope">
            <a-icon v-if="scope.row.fileType == 'img'" type="file-image" />
            <a-icon v-if="scope.row.fileType == 'pdf'" type="file-pdf" />
            <a-icon v-if="scope.row.fileType == 'ppt'" type="file-ppt" />
            <a-icon v-if="scope.row.fileType == 'doc'" type="file-word" />
            <a-icon v-if="scope.row.fileType == 'xlsx'" type="file-excel" />
            <a-icon v-if="scope.row.fileType == 'zip'" type="file-zip" />
            <span> {{ scope.row.sourceName }}.{{scope.row.fileType}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="160">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="
                ['png', 'jpg', 'jpeg', 'pdf'].indexOf(scope.row.fileType) > -1
              "
              @click="onSeePdf(scope.row)"
            >
              预览
            </el-button>
            <el-button type="text" @click="onDownLoad(scope.row)">
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="btnWrap">
        <el-button type="primary" @click="batchDownload"> 下载 </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryExistOrder,
  queryRegisterOrder,
  queryDetectOrder,
} from "@/api/EvidenceService";
import { queryObtainOrder } from "@/api/ObtainEvidence";

export default {
  name: "ExistingEvidenceFileList",

  data() {
    return {
      subject: "",
      orderNo: this.$route.query.orderNo,
      bizType: this.$route.query.bizType,
      itemType: this.$route.query.itemType,
      fileList: [],
      selectionItemIndexes: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },
    onSeePdf(item) {
      // this.$router.push({
      //       path: "/ExistingEvidence/PdfView",
      //       query: {}
      //     });
      window.open(item.downloadUrl);
    },
    loadData() {
      let that = this;
      let dataHander = ({ data }) => {
        if (that.itemType != undefined) {
          that.fileList = data.items.filter((t) => t.itemType == that.itemType);
        } else {
          that.fileList = data.items;
        }
        that.subject = data.subject;
      };
      switch (this.bizType) {
        case "exist":
          queryExistOrder(this.orderNo).then(dataHander);
          break;
        case "register":
          queryRegisterOrder(this.orderNo).then(dataHander);
          break;
        case "obtain":
          queryObtainOrder(this.orderNo).then(dataHander);
          break;
        case "detect":
          queryDetectOrder(this.orderNo).then(dataHander);
          break;
      }
    },
    onDownLoad(item) {
      window.location.href = item.downloadUrl;
    },
    batchDownload() {
      let that=this;
      if (this.selectionItemIndexes.length > 0) {
        let files=[];
        for (let i = 0; i < this.selectionItemIndexes.length; i++) {
          const index = this.selectionItemIndexes[i];
  
          this.downloadFile(this.fileList[index].downloadUrl);
        }


      }
    },
    handleSelectionChange(rows) {
      console.log(arguments)
      //用一个变量来存放被选中的index
      this.selectionItemIndexes = [];
      rows.forEach((item, index) => {
        console.log(item)
        this.selectionItemIndexes.push(index);
      });
      console.log(this.selectionItemIndexes);
    },
    downloadFile(url) {
      // download(url);
      console.log(url)
      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // 防止影响页面
      iframe.style.height = 0; // 防止影响页面
      iframe.src = url;
      document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
      // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
      setTimeout(() => {
        iframe.remove();
      }, 60 * 1000);
    },
  },
};
</script>

<style lang='scss' scoped>
@import "../../assets/css/inputForm.scss";
</style>
